import React from 'react'
import '../../assets/css/style.css';
import useAuth from '../../Auth/useAuth';
import { useLocation , useHistory, NavLink  } from 'react-router-dom';
import Imagen from '../../assets/img/Logo.png';
const Navbar = () => {
    const location = useLocation();
    const history = useHistory();
    const auth = useAuth();
    console.log(location.pathname);


    const Navegar = (pad,type) =>{

        let navbar = select('#navbar')
        if (navbar.classList.contains('navbar-mobile')) {
            navbar.classList.remove('navbar-mobile')
            let navbarToggle = select('.mobile-nav-toggle')
            navbarToggle.classList.toggle('bi-list')
            navbarToggle.classList.toggle('bi-x')
        }

        if(location.pathname!=='/' || type ==='router'){
            history.push("/"+pad);
        }
    }

    const select = (el, all = false) => {
        el = el.trim()
        if (all) {
          return [...document.querySelectorAll(el)]
        } else {
          return document.querySelector(el)
        }
      }

    const Click = () =>{
        let navbar = select('#navbar')
        if (navbar.classList.contains('navbar-mobile')) {
            navbar.classList.remove('navbar-mobile')
            let navbarToggle = select('.mobile-nav-toggle')
            navbarToggle.classList.toggle('bi-list')
            navbarToggle.classList.toggle('bi-x')
        }
        window.scrollTo(0, 0)
    }

    return (
        <>

            <section id="topbar" className="d-flex align-items-center">
                <div className="container d-flex justify-content-center justify-content-md-between">
                <div className="contact-info d-flex align-items-center">
                    <i style={{color:'white'}} className="bi bi-envelope d-flex align-items-center"><a href="mailto:egavilanezd@egdlegal.com">egavilanezd@egdlegal.com</a></i>
                    <i style={{color:'white'}} className="bi bi-phone d-flex align-items-center ms-4"><a href="tel:+593999459124">+593 999 459 124</a></i>
                </div>
                </div>
            </section>

            <header id="header" className="d-flex align-items-center ">
                <div className="container d-flex align-items-center justify-content-between">

                    <div className="logo">
                        {/* <h1><a href="#">Flexor</a></h1> */}
                        <NavLink to="/">
                            <img  onClick={()=>window.scrollTo(0,0)}  src={Imagen} width="50px" alt="" ></img>
                        </NavLink>
                        
                    </div>

                    <nav id="navbar" className="navbar">
                        <ul>
                            <>
                                {
                                    auth.isLogged() && location.pathname == '/administracion' ?
                                        <>

                                            <li onClick={()=>Click()}><NavLink className="nav-link scrollto active" to="/administracion">Publicaciones</NavLink></li>
                                            <li  onClick={auth.logout}><a className="nav-link scrollto active" style={{color:'red'}}>Cerrar sesión</a></li>
                                        </>

                                        :
                                        <>
                                            <li onClick={()=>Navegar('#hero','scroll')}><a className="nav-link scrollto active" href="#hero">Inicio</a></li>
                                            <li onClick={()=>Navegar('#about','scroll')}><a className="nav-link scrollto" href="#about">Información</a></li>
                                            {/* <li onClick={()=>Navegar('#services')}><a className="nav-link scrollto" href="#services">Servicios</a></li> */}
                                            {/* <li onClick={()=>Click()}><NavLink className="nav-link scrollto" to="/podcast">Podcast</NavLink></li> */}
                                            {/* <li onClick={()=>Click()}><NavLink className="nav-link scrollto" to="/blogs">Blogs</NavLink></li> */}
                                            {/* <li className="dropdown"><a href="#"><span>Conceptos</span> <i className="bi bi-chevron-down"></i></a>
                                                <ul>
                                                <li><a href="#">Registro de marca</a></li>
                                                <li className="dropdown"><a href="#"><span>Constitución de compañias</span> <i className="bi bi-chevron-right"></i></a>
                                                    <ul>
                                                    <li><a href="#">Modulo 1</a></li>
                                                    <li><a href="#">Modulo 2</a></li>
                                                    <li><a href="#">Modulo 3</a></li>
                                                    <li><a href="#">Modulo 4</a></li>
                                                    <li><a href="#">Modulo 5</a></li>
                                                    </ul>
                                                </li>
                                                </ul>
                                            </li> */}
                                            <li onClick={()=>Navegar('#contact','scroll')}><a className="nav-link scrollto" href="#contact">Contacto</a></li>
                                        </>
                                } 
                                {
                                    auth.isLogged() && location.pathname != '/administracion' && (
                                        <li onClick={()=>Navegar('administracion','router')}><a>Administración</a></li>
                                    )
                                } 
                            </>
                        </ul>
                        <i className="bi bi-list mobile-nav-toggle"></i>
                    </nav>

                </div>
            </header>
        </>
    )
}

export default Navbar

